angular.module('comcast.common.communication').service('RestrictFileService', ['NotificationService', function (NotificationService) {
  var restrictedVideoTypes = ['m4v', 'wmv'];

  function checkRestrictedTypes(fileName, fromSpotFileUpload) {
    var uploadArray = fileName.split('.');
    var extension = uploadArray[uploadArray.length - 1];
    var validFileList = ["ac3", "mp2", "mp3", "wav", "jpg", "mpg", "mov", "avi", "f4v", "gxf", "lxf", "mp4", "m4v", "wmv", "mxf", "flv", "pdf", "zip", "m3u8", "ts", "webm", "3gp"];

    if (restrictedVideoTypes.indexOf(extension) > -1 || validFileList.indexOf(extension) < 0 && fromSpotFileUpload) {
      NotificationService.showNotificationToast('Invalid file type selected, please try a different file type.');
      return false;
    }

    return true;
  }

  return {
    checkRestrictedTypes: checkRestrictedTypes
  };
}]);